// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Oswald:100,200,300,400,500,600,700);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,200,300,400,500,600,700);"]);
// Module
exports.push([module.id, "\n/* You can add global styles to this file, and also import other style files */\nhtml, body {\n  margin: 0px;\n  width: 100%;\n}\n", "",{"version":3,"sources":["styles.scss"],"names":[],"mappings":";AAAA,8EAAA;AAOA;EAEI,WAAW;EACX,WAAW;AAHf","file":"styles.scss","sourcesContent":["/* You can add global styles to this file, and also import other style files */\n\n@import url(\"https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900\");\n@import url(\"https://fonts.googleapis.com/css?family=Oswald:100,200,300,400,500,600,700\");\n@import url(\"https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,200,300,400,500,600,700\");\n\n\nhtml, body {\n    // background: url('/assets/bg2x.png');\n    margin: 0px;\n    width: 100%;\n}\n\n* {\n    // font-family: \"Montserrat\";\n}\n  "]}]);
// Exports
module.exports = exports;
